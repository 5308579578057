<template>
  <div>
    <van-nav-bar
      title="交易成功"
      left-arrow
      :border="false"
      placeholder
      fixed
      @click-left="$router.go(-1)"
    />

    <div style="margin-top: 100px; text-align: center; padding: 0 4%;">
      <van-icon size="65px" color="#3688ff" name="checked" />
      <p style="font-size: 14px; color: #333; margin: 16px 0 66px;">交易成功</p>
      <van-button style="margin-bottom: 18px;" round size="large" @click="comment">立即评价</van-button>
      <van-button color="#FB2B53" round size="large" @click="returnHomePage">返回首页</van-button>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      comment () {
        this.$router.replace('/commentOrder')
      },

      returnHomePage () {
        let u = navigator.userAgent
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if(isIOS) {
          window.webkit.messageHandlers.returnHomePage.postMessage('returnHomePage')
        }
        if (isAndroid) {
          window.android.returnHomePage()
        }
      }
    }
  }
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar__left {
  padding: 0 5px;
}
.van-button--large {
  height: 40px;
}
</style>